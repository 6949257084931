import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { logPageView } from "../components/Analytics/google-analytics";
import { useRouter } from "next/router";
import { trackClick, trackPage } from "../scripts/segment.js";
import { pageName, triggerLinkClick } from "../lib/utils/events_utils";
import withLogin from "../components/Login/withLogin.jsx";
import dynamic from "next/dynamic";
import Jumbotron from "../components/Index/Jumbotron";
import { SECONDS_IN_12_HOURS } from "lib/utils/constants";
const NumberStats = dynamic(() => import("components/Index/NumberStats"), {
  loading: () => <p>...</p>,
});
const OurProducts = dynamic(() => import("components/Index/OurProducts"), {
  loading: () => <p>...</p>,
});
const LeapAdvantage = dynamic(() => import("components/Index/LeapAdvantage"), {
  loading: () => <p>...</p>,
});
const Testimonials = dynamic(() => import("components/Index/Testimonials"), {
  loading: () => <p>...</p>,
});
const WhatsappSection = dynamic(
  () => import("components/Index/WhatsappSection"),
  { loading: () => <p>...</p> }
);
const MainFooter = dynamic(() => import("components/MainFooter"), {
  loading: () => <p>...</p>,
});
const SupportersInfo = dynamic(
  () => import("components/shared/SupportersInfo"),
  { loading: () => <p>...</p> }
);

const Home = (props) => {
  const { mainBannerText, mapBannerText, initLogin, productFeatures } =
    props || {};
  const [bottomCta, setBottomCta] = useState(false);
  const router = useRouter();
  const { clickId } = router.query;

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    logPageView();
    trackPage(pageName.HOME_PAGE);
    clickId && triggerLinkClick(clickId);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const verticalOffset = window.pageYOffset;
    verticalOffset > 600 ? setBottomCta(true) : setBottomCta(false);
  };
  return (
    <Layout
      title="Study Abroad Education Loan for You"
      showNavLinks
      invertLogo={false}
      bottomCta={bottomCta}
      description="Leap finance is a one-stop destination for Indian students to fund their overseas education. Low-Interest Rate ✓No Collateral ✓100% loan coverage."
      mainBannerText={mainBannerText}
    >
      {(user) => {
        return (
          <div tabIndex="-1" className="page-index">
            <Jumbotron user={user} initLogin={initLogin} />
            <NumberStats mapBannerText={mapBannerText} />
            <OurProducts
              user={user}
              initLogin={initLogin}
              features={productFeatures}
            />
            <LeapAdvantage user={user} initLogin={initLogin} />

            <Testimonials />
            <WhatsappSection />
            <SupportersInfo />
            {bottomCta && (
              <div
                className="fixed bottom-0 left-0 right-0 w-full flex flex-col items-center z-1 md:py-4 bg-blue-950 lg:static"
                style={{ zIndex: "2" }}
              >
                <button
                  type="button"
                  onClick={() => {
                    if (user) {
                      router.push("/applications?clickId=42");
                    } else {
                      trackClick(pageName.HOME_PAGE, {
                        widgetName: "Footer",
                        widgetFormat: "Banner",
                        contentName: "Get started",
                        contentFormat: "Button",
                      });
                      props?.initLogin();
                    }
                  }}
                  className="button font-bold p-4 w-full text-center md:w-1/2 lg:w-1/3 text-l"
                >
                  {user ? "Resume Application" : "Get started"}
                </button>
              </div>
            )}
            <MainFooter isBottomMarginRequired />
          </div>
        );
      }}
    </Layout>
  );
};
export async function getStaticProps() {
  const props = {};
  try {
    const axios = (await import(`axios`)).default;
    const bannerDataResponse = await axios.get(
      `${process.env.KnowledgeBaseURL}/banners?type_in=lf_main_banner&type_in=lf_map_banner`
    );
    if (bannerDataResponse?.data?.length > 0) {
      bannerDataResponse.data.forEach((val) => {
        if (val?.type === "lf_main_banner")
          props.mainBannerText = val?.description;
        else if (val?.type === "lf_map_banner")
          props.mapBannerText = val?.description;
      });
    }
    const productFeaturesResponse = await axios.get(
      `${process.env.KnowledgeBaseURL}/constants?key=lf-home-features`
    );
    props.productFeatures = productFeaturesResponse.data[0].jsonValue.features;
  } catch (err) {
    console.error(err);
  } finally {
    return { props, revalidate: SECONDS_IN_12_HOURS };
  }
}

export default withLogin(Home);
