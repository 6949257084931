export const companyContacts = {
  link: {
    phone: "tel:08047184844",
    whatsApp: "https://api.whatsapp.com/send?phone=918310156066",
    email: "mailto:contact@leapfinance.com",
  },
  display: {
    phone: "(080) 4718 4844",
    whatsApp: "+91 83101 56066",
    email: "contact@leapfinance.com",
  },
};
