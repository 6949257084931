import { useState } from "react";
import { pageName } from "../../lib/utils/events_utils";
import { trackClick } from "../../scripts/segment";
import Link from "next/link";
import Image from "next/image";
import withWindowDimensions from "../shared/withWindowDimensions";

function ProductCard({
  title,
  subTitle,
  imageUrl,
  href,
  webImageWidth,
  webImageHeight,
  mobileImageWidth,
  mobileImageHeight,
  isMobileSized,
}) {
  const [isHover, setIsHover] = useState(false);
  return (
    <Link
      href={href}
      onClick={() => {
        trackClick(pageName.HOME_PAGE, {
          widgetName: "Hero",
          widgetFormat: "Banner",
          contentName: title,
          contentFormat: "Link",
        });
      }}
      className="mr-2"
    >
      <div className="flex items-center">
        <Image
          className="w-8 md:w-auto"
          src={imageUrl}
          alt={title}
          onMouseOver={() => {
            setIsHover(true);
          }}
          onMouseOut={() => {
            setIsHover(false);
          }}
          width={isMobileSized ? mobileImageWidth : webImageWidth}
          height={isMobileSized ? mobileImageHeight : webImageHeight}
        />
        <div className="flex pl-4 md:pl-6">
          <div className="mr-2">
            <div
              className={`font-semibold ${
                isHover ? "underline" : ""
              } hover:underline`}
            >
              {title}
            </div>
            <div
              className="text-sm md:text-md"
              onMouseOver={() => {
                setIsHover(true);
              }}
              onMouseOut={() => {
                setIsHover(false);
              }}
            >
              {subTitle}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default withWindowDimensions(ProductCard);
