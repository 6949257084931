import styled from "styled-components";

const TitleTip = ({ bgColor, text, fontSize }) => {
  return (
    <TipContainer bgColor={bgColor} fontSize={fontSize}>
      {text}
    </TipContainer>
  );
};

const TipContainer = styled.div`
  background-color: ${(props) => props.bgColor};
  font-size: ${(props) => props.fontSize}px;
  padding: 8px 32px;
  color: white;
  position: relative;
  display: inline-block;
  border-radius: 23px;
  ::before {
    content: "";
    position: absolute;
    display: block;
    width: 0px;
    left: 32px;
    bottom: -15px;
    border: 13px solid transparent;
    border-bottom: 0px;
    border-top: 22px solid #29bcb7;
    transform: translate(0%, calc(0% + 4px));
    border-radius: 2px;
    @media (max-width: 768px) {
      bottom: -5px;
      left: 12px;
    }
  }
  @media (max-width: 1300px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    padding: 8px 20px;
  }
`;

export default TitleTip;
